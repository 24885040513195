@use '@sparbanken-syd/sparbanken-syd-theme/theme' as spb;
@use '@sparbanken-syd/sparbanken-syd-theme/variables' as var;
@use '@angular/material' as mat;

$t: mat.define-typography-config($font-family: 'Titillium Web',
$headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
$headline-2: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
$headline-3: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
$headline-4: mat.define-typography-level(34px, 40px, 400, 'Titillium Web', normal),
$headline-5: mat.define-typography-level(24px, 32px, 400, 'Titillium Web', normal),
$headline-6: mat.define-typography-level(22px, 30px, 600, 'Titillium Web', normal),
$subtitle-1: mat.define-typography-level(18px, 23.2px, 600, 'Titillium Web', normal),
$subtitle-2: mat.define-typography-level(16px, 22px, 400, 'Titillium Web', normal),
$body-1: mat.define-typography-level(16px, 22px, 400, 'Titillium Web', normal),
$body-2: mat.define-typography-level(16px, 22px, 400, 'Titillium Web', normal),
$caption: mat.define-typography-level(12px, 18px, 400, 'Titillium Web', normal),
$button: mat.define-typography-level(14px, 22px, 400, 'Titillium Web', normal));

@include spb.set-typography($t);

html {
  height: 100%;
  width: 100%;
  margin: 0;
}

/*
 High risk adjustment since Tillium web does not fit properly in inputs
 */
textarea {
  line-height: 28px !important;
}

body {
  font-family: sans-serif;
  height: 100%;
  margin: 0 auto;
  width: 100%;
}

/* General styling that applies to many pages */

/* Plain is just a holder for content on pages w/o hero images. */
.spb-plain-content {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  max-width: 1193px;
}

.spb-error {
  background-color: var.$spb-red;
  color: #fff;
  font-weight: bold;
  margin: 1em auto;
  padding: 0.7em;
  text-align: center;
  width: 90%;
}

/* use this to grow */
.spb-filler {
  display: flex;
  flex: 1 0;
}

/* Wrap headers, footers and content in this one */
.spb-holder {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/**
 Small tweak on form fields to make them behave
 */
.sbp-standard-full-width {
  width: 66%;
}

/**
  Checkbox labels do not line break and have to be here ... 2019-08-08 /Daniel
 */
.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-radio-label {
  white-space: normal !important;
}

.mat-checkbox-inner-container {
  margin: 4px 8px auto 0 !important;
}

a {
  color: #232323;
}

